import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { defaultFormatValues } from '../../../data/defaultFormat'
import CustomersPartners from './CustomersPartners'

import aguasAndinasLogo from './assets/images/aguasandinaslogo.svg'
import claroLogo from './assets/images/clarologo.svg'
import FinanceIcon from './assets/images/financeicon.inline.svg'
import aguasAndinasCardImg from './assets/images/foto_agua.png'
import claroCardImg from './assets/images/foto_claro.png'
import iFoodCardImg from './assets/images/foto_ifood.png'
import rappiCardImg from './assets/images/foto_rappi.png'
import segurosMundialCardImg from './assets/images/foto_seguros.png'
import iFoodLogo from './assets/images/ifoodlogo.svg'
import MarketplaceIcon from './assets/images/marketplaceicon.inline.svg'
import rappiLogo from './assets/images/rappilogo.svg'
import segurosMundialLogo from './assets/images/segurosmundiallogo.svg'
import ServicesIcon from './assets/images/servicesicon.inline.svg'
import TelecomIcon from './assets/images/telecomicon.inline.svg'

import financeBg from './assets/images/financebg.svg'
import servicesBg from './assets/images/servicesbg.svg'
import telecomBg from './assets/images/telecombg.svg'

import aguasAndinasMobileCardImg from './assets/images/foto_agua_mobile.png'
import claroMobileCardImg from './assets/images/foto_claro_mobile.png'
import iFoodMobileCardImg from './assets/images/foto_ifood_mobile.png'
import rappiMobileCardImg from './assets/images/foto_rappi_mobile.png'
import segurosMundialMobileCardImg from './assets/images/foto_seguros_mobile.png'

import './styles.scss'


const CustomersMain: React.FC = () => {
  const intl = useIntl()

  return (
    <>
      <section className="py-layout-5 customers__telecom-section">
        <img src={telecomBg} className="customers__telecom-section__bg"/>
        <div className="container">
          <h2 className="d-flex align-items-center text-primary customers-category-header">
            <TelecomIcon className="mr-4"/> <FormattedMessage id="customers.categories.telecom"/>
          </h2>

          <div className="customers-card mt-layout-6">
            <div className="customers-card__bg customers-card__bg--left"/>
            <div className="customers-card__img customers-card__img--left">
              <img src={claroCardImg} alt={`${intl.formatMessage({id: 'customers.cases.claro.name'})} image`} />
            </div>
            <div className="customers-card__body">
              <img src={claroLogo} alt={intl.formatMessage({id: 'customers.cases.claro.name'})} className="mb-7"/>

              <p className="text-dark-gray-3">
                <FormattedMessage id="customers.cases.claro.description"  values={defaultFormatValues}/>
              </p>
            </div>

            <img src={claroMobileCardImg} alt={`${intl.formatMessage({id: 'customers.cases.claro.name'})} image`} className="customers-card__img--mobile" />
          </div>

          <CustomersPartners type="telecom" className="mt-layout-6"/>
        </div>
      </section>

      <section className="py-layout-5 customers__finance-section mt-layout-7">
        <img src={financeBg} className="customers__finance-section__bg"/>
        <div className="container">
          <h2 className="d-flex align-items-center text-primary customers-category-header">
            <FinanceIcon className="mr-4"/> <FormattedMessage id="customers.categories.finance"/>
          </h2>

          <div className="customers-card mt-layout-6">
            <div className="customers-card__bg customers-card__bg--right"/>
            <div className="customers-card__body">
              <img src={segurosMundialLogo} alt={intl.formatMessage({id: 'customers.cases.seguros_mundial.name'})} className="mb-7"/>

              <p className="text-dark-gray-3">
                <FormattedMessage id="customers.cases.seguros_mundial.description"  values={defaultFormatValues}/>
              </p>
            </div>
            <div className="customers-card__img customers-card__img--right">
              <img src={segurosMundialCardImg} alt={`${intl.formatMessage({id: 'customers.cases.seguros_mundial.name'})} image`} />
            </div>
            <img src={segurosMundialMobileCardImg} alt={`${intl.formatMessage({id: 'customers.cases.seguros_mundial.name'})} image`} className="customers-card__img--mobile" />
          </div>

          <CustomersPartners type="finance" className="mt-layout-6"/>
        </div>
      </section>

      <section className="py-layout-5 bg-light-gray-3 customers__services-section mt-layout-7">
        <img src={servicesBg} className="customers__services-section__bg"/>
        <div className="container">
          <h2 className="d-flex align-items-center text-primary customers-category-header">
            <ServicesIcon className="mr-4"/> <FormattedMessage id="customers.categories.services"/>
          </h2>

          <div className="customers-card mt-layout-6">
            <div className="customers-card__bg customers-card__bg--left"/>
            <div className="customers-card__img customers-card__img--left">
              <img src={aguasAndinasCardImg} alt={`${intl.formatMessage({id: 'customers.cases.aguas_andinas.name'})} image`} />
            </div>
            <div className="customers-card__body">
              <img src={aguasAndinasLogo} alt={intl.formatMessage({id: 'customers.cases.aguas_andinas.name'})} className="mb-7"/>

              <p className="text-dark-gray-3">
                <FormattedMessage id="customers.cases.aguas_andinas.description"  values={defaultFormatValues}/>
              </p>
            </div>
            <img src={aguasAndinasMobileCardImg} alt={`${intl.formatMessage({id: 'customers.cases.aguas_andinas.name'})} image`} className="customers-card__img--mobile" />
          </div>

          <CustomersPartners type="services" className="mt-layout-6"/>
        </div>
      </section>

      <section className="py-layout-7 bg-light-gray-3 customers__marketplaces-section">
        <div className="customers__marketplaces-section__bg"/>
        <div className="container">
          <h2 className="d-flex align-items-center text-primary customers-category-header">
            <MarketplaceIcon className="mr-4"/> <FormattedMessage id="customers.categories.marketplaces"/>
          </h2>

          <div className="customers-card mt-layout-6">
            <div className="customers-card__bg customers-card__bg--right"/>
            <div className="customers-card__body">
              <img src={rappiLogo} alt={intl.formatMessage({id: 'customers.cases.rappi.name'})} className="mb-7"/>

              <p className="text-dark-gray-3">
                <FormattedMessage id="customers.cases.rappi.description"  values={defaultFormatValues}/>
              </p>
            </div>
            <div className="customers-card__img customers-card__img--right">
              <img src={rappiCardImg} alt={`${intl.formatMessage({id: 'customers.cases.rappi.name'})} image`} />
            </div>
            <img src={rappiMobileCardImg} alt={`${intl.formatMessage({id: 'customers.cases.rappi.name'})} image`} className="customers-card__img--mobile" />
          </div>

          <CustomersPartners type="marketplaces" className="my-layout-6"/>

          <div className="customers-card mt-layout-6">
            <div className="customers-card__bg customers-card__bg--left"/>
            <div className="customers-card__img customers-card__img--left">
              <img src={iFoodCardImg} alt={`${intl.formatMessage({id: 'customers.cases.ifood.name'})} image`} />
            </div>
            <div className="customers-card__body">
              <img src={iFoodLogo} alt={intl.formatMessage({id: 'customers.cases.ifood.name'})} className="mb-7"/>

              <p className="text-dark-gray-3">
                <FormattedMessage id="customers.cases.ifood.description"  values={defaultFormatValues}/>
              </p>
            </div>
            <img src={iFoodMobileCardImg} alt={`${intl.formatMessage({id: 'customers.cases.ifood.name'})} image`} className="customers-card__img--mobile" />
          </div>
        </div>
      </section>
    </>
  )
}

export default CustomersMain
