// tslint:disable: object-literal-sort-keys


import credijustoLogo from './assets/images/partners/finance/credijusto.svg'
import equidadsegurosLogo from './assets/images/partners/finance/equidadseguros.svg'
import gosocketLogo from './assets/images/partners/finance/gosocket.svg'
import principalLogo from './assets/images/partners/finance/principal.svg'
import saludsaLogo from './assets/images/partners/finance/saludsa.svg'
import suraLogo from './assets/images/partners/finance/sura.svg'
import netlifeLogo from './assets/images/partners/telecom/netlife.svg'
import telefonicaLogo from './assets/images/partners/telecom/telefonica.svg'
import tuentiLogo from './assets/images/partners/telecom/tuenti.svg'
import womLogo from './assets/images/partners/telecom/wom.svg'


import algramoLogo from './assets/images/partners/services/algramo.svg'
import awtoLogo from './assets/images/partners/services/awto.svg'
import comunidadfelizLogo from './assets/images/partners/services/comunidadfeliz.svg'
import muvoLogo from './assets/images/partners/services/muvo.svg'
import netzunLogo from './assets/images/partners/services/netzun.svg'
import ustorageLogo from './assets/images/partners/services/ustorage.svg'


import beweLogo from './assets/images/partners/marketplaces/bewe.svg'
import fitcoLogo from './assets/images/partners/marketplaces/fitco.svg'
import fitpassLogo from './assets/images/partners/marketplaces/fitpass.svg'
import indriverLogo from './assets/images/partners/marketplaces/indriver.svg'
import mensajerosurbanosLogo from './assets/images/partners/marketplaces/mensajerosurbanos.svg'


export const partners = {
  telecom: [
    {
      name: 'Telefónica',
      img: telefonicaLogo
    },
    {
      name: 'WOM',
      img: womLogo
    },
    {
      name: 'Netlife',
      img: netlifeLogo
    },
    {
      name: 'Tuenti',
      img: tuentiLogo
    },
  ],
  finance: [
    {
      name: 'Sura',
      img: suraLogo,
    },
    {
      name: 'Principal',
      img: principalLogo,
    },
    {
      name: 'Salud SA',
      img: saludsaLogo,
    },
    {
      name: 'Credijusto',
      img: credijustoLogo,
    },
    {
      name: 'Seguros La Equidad',
      img: equidadsegurosLogo,
    },
    {
      name: 'Go Socket',
      img: gosocketLogo,
    },
  ],
  services: [
    {
      name: 'U-Storage',
      img: ustorageLogo,
    },
    {
      name: 'Netzun',
      img: netzunLogo,
    },
    {
      name: 'Awto',
      img: awtoLogo,
    },
    {
      name: 'Algramo',
      img: algramoLogo,
    },
    {
      name: 'Comunidad Feliz',
      img: comunidadfelizLogo,
    },
    {
      name: 'Muvo',
      img: muvoLogo,
    },
  ],
  marketplaces: [
    {
      name: 'Mensajeros Urbanos',
      img: mensajerosurbanosLogo,
    },
    {
      name: 'Bewe',
      img: beweLogo,
    },
    {
      name: 'FitCo',
      img: fitcoLogo,
    },
    {
      name: 'Fitpass',
      img: fitpassLogo,
    },
    {
      name: 'InDriver',
      img: indriverLogo,
    },
  ]
}
