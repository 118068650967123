import React from 'react';
import IndexLayout from '../layouts';

import Footer from '../components/Footer/Footer';
import SEO from '../components/SEO/SEO';
import { LocaleTypes } from '../data/locales';

import CustomersHero from '../components/Customers/CustomersHero/CustomersHero';
import CustomersMain from '../components/Customers/CustomersMain/CustomersMain';
import IndexCTA from '../components/Index/IndexCTA/IndexCTA';

interface CustomersPageProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const CustomersPage: React.FC<CustomersPageProps> = ({ pathContext, location }) => {

  const content = () => (
    <>
      <SEO titleId="customers.title" descriptionId="customers.description" />
      <CustomersHero/>
      <CustomersMain/>
      <IndexCTA/>
      <Footer theme="primary" />
    </>
  );

  return <IndexLayout render={ content } locale={pathContext.localeCode}  location={location} />
}

export default CustomersPage;
