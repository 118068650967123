
import React from 'react'
import {partners} from './partners'

interface CustomersPartnersProps {
  type: keyof (typeof partners)
  className?: string
}

const CustomersPartners: React.FC<CustomersPartnersProps> = ({type, className}) => (
  <div className={`customers-partners-wrapper ${className}`}>
    <div className={`customers-partners`}>
      {
        partners[type].map(partner =>
          <img
            src={partner.img}
            alt={partner.name}
            key={partner.name}
            className="customers-partners__item"
          />
        )
      }
    </div>
  </div>
)

export default CustomersPartners
