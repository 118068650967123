import React from 'react';
import { FormattedMessage } from 'react-intl';

import HeroImg from './assets/images/hero.inline.svg';

import './styles.scss';

import CustomersFloatingScroll from '../CustomersFloatingScroll/CustomersFloatingScroll';


const CustomersHero: React.FC = () => {

  return (
    <section className="hero customers-hero bg-light-gray-2 mb-layout-7">
      <div className="customers-hero-slant"/>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8 offset-lg-2 text-center">
            <h1 className="display-4 text-primary">
              <FormattedMessage id="customers.hero.title"/>
            </h1>
            <p className="lead mt-5" style={{whiteSpace: 'pre-wrap'}}>
              <FormattedMessage id="customers.hero.subtitle"/>
            </p>
          </div>
        </div>
      </div>
      <CustomersFloatingScroll className="mt-layout-5"/>

      <div className="customers-hero-image d-none d-lg-block">
        <HeroImg/>
      </div>
    </section>
  )
};

export default CustomersHero;
